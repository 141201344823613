<template>
  <div>
    <b-card>
      <b-form-group
        label="Client ID"
        label-for="client_id"
      >
        <b-form-input
          id="client_id"
          v-model="itemData.client_id"
          placeholder="Client ID"
        />
      </b-form-group>
      <b-form-group
        label="Client Secret"
        label-for="client_secret"
      >
        <b-form-input
          id="client_secret"
          v-model="itemData.client_secret"
          placeholder="Client Secret"
        />
      </b-form-group>
      <b-form-group
        label="Kanal Adı"
        label-for="channel_name"
      >
        <b-form-input
          id="channel_name"
          v-model="itemData.channel_name"
          placeholder="Client Secret"
        />
      </b-form-group>
      <b-form-group
        label="Site Adresi"
        label-for="redirect_url"
      >
        <b-form-input
          id="redirect_url"
          v-model="itemData.redirect_url"
          placeholder="Site Adresi (örnek:localhost)"
        />
      </b-form-group>
      <b-form-group
        label="Access Token"
        label-for="access_token"
      >
        <b-input-group>
          <b-form-input
            id="access_token"
            v-model="itemData.access_token"
            placeholder="Access Token"
            readonly
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="getAccessToken"
            >
              <b-spinner
                v-if="getAccess"
                small
              />
              Getir
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="Kullanıcı Yetkilendirme Tokeni"
        label-for="user_access_token"
      >
        <b-input-group>
          <b-form-input
            id="user_access_token"
            v-model="itemData.user_access_token"
            placeholder="Kullanıcı Yetkilendirme Tokeni"
            readonly
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="getUserAccessToken"
            >
              <template v-if="itemData.user_access_token">
                Tekrar Yetkilendir
              </template>
              <template v-else>
                Yetkilendir
              </template>
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <small>Yetkilendirme işleminin tamamlanabilmesi için diğer alanların doldurulup formun kaydedilmesi gerekir.</small>
      </b-form-group>
    </b-card>
    <b-button
      variant="primary"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'TwitchApiConfigIndex',
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
  },
  data() {
    return {

      getAccess: false,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['twitchConfigApi/dataItem']
    },
    saveData() {
      return this.$store.getters['twitchConfigApi/dataSaveStatus']
    },
    getUserAccessTokenURL() {
      return this.$store.getters['twitchConfigApi/getUserAccessTokenUrl']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getData()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('twitchConfigApi/getDataItem')
        .then(() => {
          const token = this.getAccessTokenFromUrl(window.location.href)
          if (token) {
            this.itemData.user_access_token = token
            this.showToast({
              variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: 'Kullanıcı Yetkilendirme Tokeni başarı ile getirildi. "Kaydet" butonuna basmayı unutmayın.',
            })
          }
        })
    },
    submitForm() {
      if (!this.itemData.client_id && !this.itemData.client_secret && !this.itemData.channel_name && !this.redirect_url) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: 'Client İd,Client Secret, Site Adresi alanlarını doldurun.',
        })
      } else {
        this.$store.dispatch('twitchConfigApi/saveData', this.itemData)
      }
    },
    getAccessToken() {
      this.getAccess = true
      this.$store.dispatch('twitchConfigApi/getAccessToken')
        .then(response => {
          if (response.status) {
            this.itemData.access_token = response.access_token
            this.showToast({
              variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: 'Access Token başarı ile getirildi. "Kaydet" butonuna basmayı unutmayın.',
            })
          } else {
            this.showToast({
              variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: `${response.message}Bu işlemi gerçekleştirmeden önce formu kaydetmeyi unutmayın.`,
            })
          }
          this.getAccess = false
        })
    },
    getUserAccessToken() {
      this.$store.dispatch('twitchConfigApi/setUserAccessTokenUrl')
        .then(response => {
          if (response.url) {
            window.location.href = response.url
          } else {
            this.showToast({
              variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: 'Yetkilendirme işlemini yapabilmeniz için diğer alanları doldurup kaydetmeniz gerekmektedir.',
            })
          }
        })
    },
    getAccessTokenFromUrl(url) {
      // URL'yi "#" karakterinden itibaren böleriz
      const queryString = url.split('#')[1]
      if (queryString) {
        // URL parametrelerini parçalara ayırırız
        const params = queryString.split('&')

        // Parametreleri döngüye alır ve access_token parametresini buluruz
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < params.length; i++) {
          const param = params[i].split('=')
          if (param[0] === 'access_token') {
            // Eğer access_token parametresi bulunursa, değerini döndürürüz
            return decodeURIComponent(param[1])
          }
        }
      }
      // Eğer access_token parametresi bulunamazsa, null döndürürüz
      return null
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
